<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP INVOICE VIEW

type    : view

uses    : main-container
          header-view
          main-card

route   : /invoices/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-invoice-view" id="background" v-if="componentCreated">
    <header-view
      v-if="invoice"
      title="Invoice"
      short_title=""
      :status="invoice.status"
      :job_uuid="invoice.job_uuid"
      :id="'INV-' + invoice.number.toString().padStart(5, '0')"
      view_phase="Invoice"
      edit_url=""
      :allow_edit="!editMode && !invoiceLocked"
      :allow_download="true"
      :download_url="this.pdfUrl"
      :btnOptions="headerButtons"
      :previous_page="prevRoute" />
    <main-container v-if="invoice">
      <!-- this should be left active even if quickbooks is disabled for legacy invoices -->
      <span class="red--text" v-show="invoice.quickbooks_id">
        This invoice has been imported into quickbooks and can only be edited
        there. The copy shown below may not reflect changes since creation.
      </span>
      <main-card>
        <v-layout align-start>
          <v-flex xs4>
            <v-img
              :src="jobsLogo"
              height="84"
              contain
              class="pa-2 mt-4"
              position="left center">
            </v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs2 class="mt-4">
            <span class="font-weight-bold">Invoice Number:</span><br />
            <span class="font-weight-bold">Invoice Date:</span><br />
            <span class="font-weight-bold">Status:</span><br />
          </v-flex>
          <v-flex xs2 class="mt-4">
            INV-{{ invoice.number.toString().padStart(5, '0') }}<br />
            {{
              displayDateFormat(
                invoice.issue_date,
                $auth.tenantProfile.date_format
              )
            }}<br />
            {{ invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)
            }}<br />
          </v-flex>
        </v-layout>
        <v-layout row class="my-5">
          <v-divider></v-divider>
        </v-layout>
        <v-layout row class="ma-2">
          <v-flex xs4>
            <span class="title">Bill to:</span><br />
            <span v-show="!editMode">{{ invoice.client_name }}<br /></span>

            <v-text-field
              v-model="invoice.client_name"
              v-show="editMode"></v-text-field>

            <span v-show="!editMode">{{ invoice.client_address }}<br /></span>
            <v-text-field
              v-model="invoice.client_address"
              v-show="editMode"></v-text-field>
            <span v-show="!editMode"
              >{{ invoice.client_city }} {{ invoice.client_state }},{{
                invoice.client_postal_code
              }}<br
            /></span>
            <v-text-field
              v-model="invoice.client_city"
              v-show="editMode"></v-text-field>
            <v-text-field
              v-model="invoice.client_state"
              v-show="editMode"></v-text-field>
            <v-text-field
              v-model="invoice.client_postal_code"
              v-show="editMode"></v-text-field>
          </v-flex>
          <v-flex xs4>
            <span class="title">Make payments to:</span><br />
            <span v-show="!editMode">{{ invoice.tenant_name }}<br /></span>

            <v-text-field
              v-model="invoice.tenant_name"
              v-show="editMode"></v-text-field>

            <span v-show="!editMode">{{ invoice.tenant_address }}<br /></span>
            <v-text-field
              v-model="invoice.tenant_address"
              v-show="editMode"></v-text-field>
            <span v-show="!editMode"
              >{{ invoice.tenant_city }} {{ invoice.tenant_state }}
              {{ invoice.tenant_postal_code }}<br
            /></span>
            <v-text-field
              v-model="invoice.tenant_city"
              v-show="editMode"></v-text-field>
            <v-text-field
              v-model="invoice.tenant_state"
              v-show="editMode"></v-text-field>
            <v-text-field
              v-model="invoice.tenant_postal_code"
              v-show="editMode"></v-text-field>
          </v-flex>
          <v-flex xs4>
            <span class="font-weight-bold"
              >Invoice Total: {{ formatCurrency(total, currencySymbol) }}</span
            ><br />
            <span class="font-weight-bold"
              >Payment Received:
              {{ formatCurrency(paid, currencySymbol) }}</span
            ><br />
            <span class="font-weight-bold"
              >Remaining Balance:
              {{ formatCurrency(balance, currencySymbol) }}</span
            ><br />
          </v-flex>
        </v-layout>
        <v-layout row class="my-4">
          <v-divider></v-divider>
        </v-layout>
        <v-sheet class="d-flex" color="grey lighten-3">
          <v-container>
            <v-layout>
              <v-flex xs9>
                <span class="font-weight-bold">Description</span>
              </v-flex>
              <v-flex xs3>
                <span class="font-weight-bold">Amount</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-sheet>

        <!-- Invoice Items -->
        <v-sheet v-if="invoiceItems">
          <v-container>
            <v-layout
              v-for="(item, index) in invoiceItems"
              v-bind:key="item.uuid"
              row>
              <v-flex xs9 class="pl-2">
                {{ invoiceItems[index].description }}
              </v-flex>
              <v-flex x3>
                {{ formatCurrency(invoiceItems[index].amount, currencySymbol) }}
              </v-flex>
            </v-layout>
          </v-container>
        </v-sheet>

        <!-- Invoice Payments -->
        <br />
        <v-sheet v-if="invoicePayments">
          <v-container>
            <v-layout
              v-for="(item, index) in invoicePayments"
              v-bind:key="item.uuid"
              row>
              <v-flex xs9 class="pl-2">
                Payment - {{ invoicePayments[index].description }}
              </v-flex>
              <v-flex xs3>
                {{
                  formatCurrency(invoicePayments[index].amount, currencySymbol)
                }}
              </v-flex>
            </v-layout>
          </v-container>
        </v-sheet>

        <!--Invoice Footer -->
        <v-divider> </v-divider>
        <v-layout row class="mt-5 mr-2 ml-2" justify-space-between>
          <v-flex xs6>
            <h4>Notes:</h4>
            <p class="pr-2">
              <span v-show="!editMode">{{ invoice.notes }}</span>
              <v-textarea
                v-model="invoice.notes"
                v-show="editMode"
                class="mr-2"
                outlined />
            </p>
          </v-flex>
          <!-- Invoice Totals -->
          <v-flex xs6>
            <v-sheet color="grey lighten-3">
              <v-container>
                <v-layout>
                  <v-flex xs6> Subtotal: </v-flex>
                  <v-flex xs6>
                    {{ formatCurrency(subtotal, currencySymbol) }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6> Discount: </v-flex>
                  <v-flex xs6>
                    <span v-show="!editMode">{{
                      invoice.discount
                        ? formatCurrency(invoice.discount, currencySymbol)
                        : formatCurrency('0.00', currencySymbol)
                    }}</span>
                    <v-text-field
                      class="mt-n1 mb-2 py-0 mr-4"
                      v-model="invoice.discount"
                      v-show="editMode"
                      :prefix="currencySymbol"
                      :rules="validate_discount"
                      placeholder="0"
                      :hide-details="true" />
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex xs6>
                    Tax Rate
                    <span>({{ taxType }})</span>:
                  </v-flex>
                  <v-flex xs6>
                    <span v-show="!editMode">{{ taxRate }}%</span>
                    <v-text-field
                      class="mt-n1 mb-2 py-0 mr-4"
                      v-model="invoice.tax_rate"
                      v-show="editMode"
                      suffix="%"
                      :rules="validate_percentage"
                      :hide-details="true" />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6> Tax: </v-flex>
                  <v-flex xs6>
                    {{ formatCurrency(tax, currencySymbol) }}
                  </v-flex>
                </v-layout>

                <!--
              <v-layout>
                <v-flex xs4>
                  Tax <span> ({{ taxType }}{{ taxRate }}%)</span>:
                </v-flex>
                <v-flex xs2>
                  <v-text-field class="mt-n1 py-0 px-2 mr-4"
                                v-model="invoice.tax_rate"
                                v-show="editMode"
                                suffix="%"
                                label="tax rate"
                                :hide-details="true" />
                </v-flex>
                <v-flex xs6>
                  {{ formatCurrency(tax,currencySymbol) }}
                </v-flex>
              </v-layout>
            -->
                <v-layout>
                  <v-flex xs6> Total: </v-flex>
                  <v-flex xs6>
                    {{ formatCurrency(total, currencySymbol) }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6> Paid: </v-flex>
                  <v-flex xs6>
                    {{ formatCurrency(paid, currencySymbol) }}
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout>
                  <v-flex xs6> Balance Due: </v-flex>
                  <v-flex xs6>
                    {{ formatCurrency(balance, currencySymbol) }}
                  </v-flex>
                </v-layout>
              </v-container>
            </v-sheet>
          </v-flex>
          <v-flex class="mt-5 mb-2 ml-2">
            <h4>Terms and Conditions:</h4>
            <p>
              <span v-show="!editMode">{{ invoice.terms }} </span>
            </p>
            <v-textarea
              v-model="invoice.terms"
              auto-grow
              outlined
              v-show="editMode"></v-textarea>
          </v-flex>
        </v-layout>
      </main-card>
    </main-container>

    <!-- ADD PAYMENT DIALOG -->
    <v-dialog
      v-if="invoicePayment"
      v-model="addPaymentDialog"
      width="500"
      persistent>
      <v-card color="grey lighten-4" min-width="350px" flat>
        <header-view
          dialog
          previous_page="NO_BACK_BUTTON"
          title="Add Payment"
          closeBtn
          :closeAction="
            () => {
              addPaymentDialog = false;
            }
          "
          :btnOptions="[
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-check',
              action: this.addPaymentToInvoice,
            },
          ]" />
        <v-card-text>
          <v-form ref="addPaymentForm">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Payment Description"
                    v-model="invoicePayment.description"
                    hint=""
                    required />
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Payment Amount"
                    v-model="invoicePayment.amount"
                    hint=""
                    :rules="[
                      ...validate_money_amount,
                      ...validate_invoice_payment,
                    ]"
                    required
                    :prefix="currencySymbol"
                    @blur="
                      formatMoney({
                        object: 'invoicePayment',
                        attribute: 'amount',
                      })
                    " />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import HeaderView from '@/components/header-view';

  // mixins
  import Localization from '@/mixins/localization';
  import Forms from '@/mixins/forms.js';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';

  // services
  import Invoices from '@/services/Invoices.service.js';

  export default {
    name: 'AppInvoiceView',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
    },
    mixins: [Forms, dateTimeHelpers, Localization],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        editMode: false,
        addPaymentDialog: false,
        jobsLogo: require('../../assets/TP-Jobs-RGB.png'),
        componentCreated: false,
        pdfUrl: false,
        invoice: null,
        invoiceItems: null,
        invoicePayments: null,
        invoicePayment: null,
      };
    },
    beforeRouteEnter(to, from, next) {
      // since its possible to route here from more than one view, this will
      // help us route the back button correctly

      next((vm) => {
        if (from && from.path && from.path != '/') {
          vm.prevRoute = from.path;
        } else {
          vm.prevRoute = '/invoices';
        }
      });
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      invoiceLocked: function () {
        let locked = false;
        if (this.invoice.quickbooks_id) {
          //if this is a quickbooks invoice, it can only be modified in quickbooks.
          locked = true;
        }
        return locked;
      },
      subtotal: function () {
        var i = 0;
        var subtotal = 0;
        for (i = 0; i < this.invoiceItems.length; i++) {
          subtotal = subtotal + parseFloat(this.invoiceItems[i].amount);
        }
        return Number(subtotal).toFixed(2);
      },
      taxType() {
        return this.invoice.tax_type
          ? ''
          : this.$auth.tenantProfile.tax_type
          ? this.$auth.tenantProfile.tax_type + ' '
          : '';
      },
      taxRate() {
        return this.invoice.tax_rate
          ? this.invoice.tax_rate
          : this.$auth.tenantProfile.tax_rate
          ? this.$auth.tenantProfile.tax_rate
          : '0.0';
      },
      tax: function () {
        var tax = this.invoice.tax_rate
          ? this.invoice.tax_rate
          : this.$auth.tenantProfile.tax_rate
          ? this.$auth.tenantProfile.tax_rate
          : 0;
        tax *= 0.01;
        return Number.parseFloat(
          (this.subtotal - this.invoice.discount) * tax
        ).toFixed(2);
      },
      total: function () {
        return Number(
          Number(this.subtotal) -
            Number(this.invoice.discount) +
            Number(this.tax)
        ).toFixed(2);
      },
      paid: function () {
        var i = 0;
        var paid = 0;
        for (i = 0; i < this.invoicePayments.length; i++) {
          paid = paid + Number(this.invoicePayments[i].amount);
        }
        return Number.parseFloat(paid).toFixed(2);
      },
      balance: function () {
        return Number(this.total - this.paid);
      },
      headerButtons: function () {
        if (this.invoiceLocked) {
          return [];
        }

        var buttons = [];

        if (!this.editMode) {
          buttons.push({
            name: '',
            icon: 'mdi-pencil',
            btnColor: 'secondary',
            action: () => {
              this.editMode = true;
            },
          });
          buttons.unshift({
            name: 'Add Payment',
            btnColor: 'button-primary',
            action: this.addPayment,
          });
        } else {
          buttons.push({
            name: 'Save',
            btnColor: 'button-primary',
            action: this.save,
          });
        }
        return buttons;
      },
      validate_invoice_payment: function () {
        return [
          (v) =>
            this.balance - v >= 0 ||
            'Payment is greater than remaining invoice balance',
        ];
      },
    },
    async created() {
      if (!this.uuid) {
        console.log('invoiceview: no uuid passed in!');
        return;
      }

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      this.invoice = await Invoices.getInvoice(this.uuid, accessToken);

      if (this.invoice) {
        // console.log(this.invoice)
        this.invoiceItems = await Invoices.getInvoiceItems(
          {
            invoice_uuid: this.uuid,
          },
          accessToken
        );

        this.invoicePayments = await Invoices.getInvoicePayments(
          {
            invoice_uuid: this.uuid,
          },
          accessToken
        );

        await this.generatePDF();

        // set custom logo (if one is set)
        if (this.$auth.tenantProfile.logo) {
          this.jobsLogo = this.$auth.tenantProfile.logo;
        }

        this.componentCreated = true;
      } else {
        console.log('could not get invoice!');
      }
    },
    methods: {
      edit: async function () {
        this.editMode = true;
      },
      save: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var res = await Invoices.updateInvoice(
          this.invoice.uuid,
          this.invoice,
          accessToken
        );

        if (res) {
          this.generatePDF();

          this.editMode = false;
        } else {
          console.log('failed to update invoice!');
        }
      },
      addPayment: async function () {
        this.invoicePayment = Invoices.blankInvoicePayment();
        this.invoicePayment.invoice_uuid = this.invoice.uuid;
        this.invoicePayment.tenant_uuid = this.invoice.tenant_uuid;
        this.addPaymentDialog = true;
      },
      async addPaymentToInvoice() {
        if (!this.$refs.addPaymentForm.validate()) {
          return false;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // Create new invoice payment
        var res = await Invoices.createInvoicePayment(
          this.invoicePayment,
          accessToken
        );

        // validate
        if (res) {
          // get new invoice payment list
          var res_payments = await Invoices.getInvoicePayments(
            {
              invoice_uuid: this.uuid,
            },
            accessToken
          );

          // validate
          if (res_payments) {
            // update invoice payment list
            this.invoicePayments = res_payments;

            // check if invoice has been paid off
            if (this.total === this.paid) {
              this.invoice.status = 'paid';
              await Invoices.updateInvoice(
                this.invoice.uuid,
                this.invoice,
                accessToken
              );
            }

            // re-generate pdf
            this.generatePDF();

            // close dialog
            this.addPaymentDialog = false;
          } else {
            console.log(
              'error reloading invoices after creating invoice payment!'
            );
          }
        } else {
          console.log('error creating invoice payment!');
        }
      },
      async generatePDF() {
        // this will start the spinner button
        this.pdfUrl = '';

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var res = await Invoices.exportSnapshot(
          {
            uuid: this.invoice.uuid,
          },
          accessToken
        );

        if (res && res.location) {
          this.pdfUrl = res.location;
        } else {
          this.pdfUrl = '';
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #invoice-page {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    width: 90%;
  }

  #invoice-page .layout {
    padding: 8px !important;
  }
</style>
